import { createReducer } from '@ngrx/store';

export interface ConfigState {
  expandedLogoSrc: string;
  expandedLogoWidth: number;
  expandedLogoHeight: number;
  collapsedLogoSrc: string;
  collapsedLogoWidth: number;
  collapsedLogoHeight: number;
  dotHeaderMode: boolean;
}

export const initialState: ConfigState = {
  expandedLogoSrc: '/images/logo-expanded.png',
  expandedLogoWidth: 205,
  expandedLogoHeight: 42,
  collapsedLogoSrc: '/images/logo-collapsed.png',
  collapsedLogoWidth: 44,
  collapsedLogoHeight: 42,
  dotHeaderMode: false,
};

export const configFeatureKey = 'config';

export const configReducer = createReducer(initialState);
