<div class="w-[1536px] h-[386px] rounded-[50%] bg-action-secondary translate-x-[-388px] translate-y-[-190px] blur-2xl fixed top-0"></div>
<div class="flex z-[1]">
  <div class="p-2 flex flex-col sticky top-0 self-start z-[1]">
    <app-side-menu [vm]="sideMenuVm()" (expandChange)="onSideMenuExpandChange($event)" />
  </div>
  <div class="flex flex-col grow min-w-0 px-6 pb-10">
    <div class="flex items-center justify-between py-4 px-8">
      <div class="flex flex-col">
        <app-breadcrumbs [vm]="breadcrumbsVm()" />
        @if (title$Signal() | async; as title) {
          <span class="text-header-title text-[40px] font-medium">{{ title }}</span>
        } @else {
          <div class="py-[10px]">
            <app-loading-skeleton class="w-[200px] h-[40px]" viewBox="0 0 200 40">
              <svg:rect x="0" y="0" rx="4" ry="4" width="200" height="40" />
            </app-loading-skeleton>
          </div>
        }
      </div>
      <app-header-profile-section [vm]="headerProfileVm()" />
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
