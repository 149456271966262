/* eslint-disable unicorn/consistent-function-scoping */
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, map, switchMap } from 'rxjs/operators';

import { WebsocketService } from '@core/services/websocket.service';

import {
  connectToWebsocket,
  connectToWebsocketSuccess,
  reconnectToWebsocketIsSafe,
  websocketClosed,
  websocketFailure,
} from './websocket.actions';

@Injectable()
export class WebsocketEffects {
  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store);
  readonly #websocketService = inject(WebsocketService);

  public connectToWebsocket$ = createEffect(
    () =>
      this.#actions$.pipe(
        ofType(connectToWebsocket, websocketFailure, websocketClosed),
        map(() => {
          this.#websocketService.connect();
        })
      ),
    {
      dispatch: false,
    }
  );

  public emitReconnectToWebsocketIsSafe$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(websocketFailure),
      switchMap(() => {
        return this.#actions$.pipe(ofType(connectToWebsocketSuccess));
      }),
      delay(0),
      map(() => {
        return reconnectToWebsocketIsSafe();
      })
    )
  );
}
