import { ApplicationConfig } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { CONFIG_TOKEN } from '@core/tokens';
import { Config } from '@core/types';

import { environment } from '@environments/environment';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

document.addEventListener('DOMContentLoaded', async () => {
  try {
    const response = await fetch('config.json');
    const config: Config = await response.json();

    const extendedConfig: Config = {
      apiIsMocked: config.apiIsMocked ?? environment.apiIsMocked,
      backendRootUrl: config.backendRootUrl ?? environment.backendRootUrl,
      backendWebsocketUrl: config.backendWebsocketUrl ?? environment.backendWebsocketUrl,
      mapboxToken: config.mapboxToken,
    };
    const extendedAppConfig: ApplicationConfig = {
      ...appConfig,
      providers: [
        ...appConfig.providers,
        {
          provide: CONFIG_TOKEN,
          useValue: extendedConfig,
        },
      ],
    };

    if (extendedConfig.apiIsMocked) {
      const { worker } = await import('./mocks/browser');
      await worker(extendedConfig.backendRootUrl, extendedConfig.backendWebsocketUrl).start({
        serviceWorker: { url: '/mockServiceWorker.js' },
      });
    }

    await bootstrapApplication(AppComponent, extendedAppConfig);
  } catch (error) {
    console.error(error);
  }
});
