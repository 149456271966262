<div class="relative flex rounded-radius-circle bg-header-actionitem-bg">
  <svg-icon key="notification-40" size="40"></svg-icon>
  @if (vm().hasNotifications) {
    <div class="h-[10px] w-[10px] bg-status-error rounded-radius-circle absolute top-[6px] right-[7px]"></div>
  }
</div>
<div class="flex items-center gap-2">
  <div class="flex rounded-radius-circle bg-header-actionitem-bg">
    <svg-icon key="profile-40" size="40"></svg-icon>
  </div>
  <span class="text-xm font-medium">{{ vm().name }}</span>
</div>
