import { APP_INITIALIZER, Provider } from '@angular/core';
import { Store } from '@ngrx/store';

import { connectToWebsocket } from '@core/stores/websocket/websocket.actions';

const websocketFactory = (store: Store) => {
  return (): void => {
    store.dispatch(connectToWebsocket());
  };
};

export const websocketInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: websocketFactory,
  deps: [Store],
  multi: true,
};
