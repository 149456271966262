import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingSkeletonComponent } from '@shared/components/loading-skeleton';

import { BreadcrumbsComponent } from './components/breadcrumbs';
import { HeaderProfileSectionComponent } from './components/header-profile-section';
import { SideMenuComponent } from './components/side-menu';
import { LayoutService } from './layout.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    SideMenuComponent,
    BreadcrumbsComponent,
    TranslateModule,
    AsyncPipe,
    HeaderProfileSectionComponent,
    LoadingSkeletonComponent,
  ],
  providers: [LayoutService],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  readonly #layoutService = inject(LayoutService);

  public readonly sideMenuVm = this.#layoutService.sideMenuVm;
  public readonly breadcrumbsVm = this.#layoutService.breadcrumbsVm;
  public readonly title$Signal = this.#layoutService.title$Signal;
  public readonly headerProfileVm = this.#layoutService.headerProfileVm;

  public onSideMenuExpandChange(expanded: boolean): void {
    this.#layoutService.onSideMenuExpandChange(expanded);
  }
}
