import { createReducer, on } from '@ngrx/store';

import { setSideMenuExpanded } from './layout.actions';

export interface LayoutState {
  sideMenuExpanded: boolean;
}

export const initialState: LayoutState = {
  sideMenuExpanded: true,
};

export const layoutFeatureKey = 'layout';

export const layoutReducer = createReducer(
  initialState,

  on(setSideMenuExpanded, (state, { expanded }) => ({ ...state, sideMenuExpanded: expanded }))
);
