import { createSelector } from '@ngrx/store';

import { AssetGraphHistoryGraphViewModel } from './components/asset-graph-history-graph';
import { SiteGraphHistoryGraphViewModel } from './components/site-graph-history-graph';
import { SiteOverviewTableViewModel } from './components/site-overview-table';
import { DashboardAssetDetailsVm, DashboardAssetSelectorVm, DashboardSiteDetailsVm, DashboardSiteSelectorVm } from './dashboard.view-model';
import {
  selectAssetHistory,
  selectAssetHistoryError,
  selectAssetHistoryIsLoading,
  selectRefreshAssetHistoryError,
  selectRefreshAssetHistoryIsLoading,
  selectRefreshSiteDataError,
  selectRefreshSiteDataIsLoading,
  selectSelectedAssetId,
  selectSelectedSiteId,
  selectSiteDataError,
  selectSiteDataIsLoading,
  selectSiteList,
  selectSiteListError,
  selectSiteListIsLoading,
  selectSiteMetering,
  selectSiteOverview,
  selectSiteStructure,
  selectSiteStructureError,
  selectSiteStructureIsLoading,
} from './store/dashboard.selectors';

export const selectDashboardSiteSelectorVm = createSelector(
  selectSiteList,
  selectSiteListIsLoading,
  selectSiteListError,
  selectSelectedSiteId,
  (siteList, siteListIsLoading, siteListError, selectedSiteId): DashboardSiteSelectorVm => ({
    sites: siteList,
    sitesIsLoading: !!siteListIsLoading,
    sitesError: siteListError,
    selectedSiteId: selectedSiteId ?? null,
  })
);

export const selectDashboardSiteDetailsVm = createSelector(
  selectSiteStructureIsLoading,
  selectSiteStructureError,
  selectSiteOverview,
  selectSiteMetering,
  selectSiteDataIsLoading,
  selectSiteDataError,
  (siteStructureIsLoading, siteStructureError, siteOverview, siteMetering, siteDataIsLoading, siteDataError): DashboardSiteDetailsVm => ({
    siteDataIsLoading: !!siteStructureIsLoading || (!!siteDataIsLoading && (!siteOverview || !siteMetering)),
    siteDataError: siteStructureError || siteDataError,
    siteOverview,
    siteMetering,
  })
);

export const selectSiteOverviewTableViewModel = createSelector(
  selectSiteOverview,
  selectRefreshSiteDataError,
  selectRefreshSiteDataIsLoading,
  (siteOverview, refreshSiteDataError, refreshSiteDataIsLoading): SiteOverviewTableViewModel | undefined =>
    siteOverview && {
      tableData: siteOverview,
      isLoading: !!refreshSiteDataIsLoading,
      error: refreshSiteDataError,
    }
);

export const selectSiteGraphHistoryGraphViewModel = createSelector(
  selectSiteMetering,
  selectRefreshSiteDataError,
  selectRefreshSiteDataIsLoading,
  (siteMetering, refreshSiteDataError, refreshSiteDataIsLoading): SiteGraphHistoryGraphViewModel | undefined =>
    siteMetering && {
      graphData: siteMetering.meteredData,
      isLoading: !!refreshSiteDataIsLoading,
      error: refreshSiteDataError,
    }
);

export const selectDashboardAssetSelectorVm = createSelector(
  selectSiteStructure,
  selectSelectedAssetId,
  (siteStructure, selectedAssetId): DashboardAssetSelectorVm => {
    const assets = siteStructure?.assets;

    return { assets, selectedAssetId: selectedAssetId ?? null };
  }
);

export const selectDashboardAssetDetailsVm = createSelector(
  selectAssetHistory,
  selectAssetHistoryIsLoading,
  selectAssetHistoryError,
  (assetHistory, assetDataIsLoading, assetDataError): DashboardAssetDetailsVm => ({
    assetHistory: assetHistory?.meteredData,
    assetDataIsLoading: !!assetDataIsLoading,
    assetDataError,
  })
);

export const selectAssetGraphHistoryGraphViewModel = createSelector(
  selectAssetHistory,
  selectRefreshAssetHistoryIsLoading,
  selectRefreshAssetHistoryError,
  (assetHistory, refreshAssetHistoryIsLoading, refreshAssetHistoryError): AssetGraphHistoryGraphViewModel | undefined =>
    assetHistory && {
      graphData: assetHistory.meteredData,
      isLoading: !!refreshAssetHistoryIsLoading,
      error: refreshAssetHistoryError,
    }
);
