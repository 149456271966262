import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SvgIconComponent, injectRegisterIcons } from '@ngneat/svg-icon';

import { LoadingSkeletonComponent } from '@shared/components/loading-skeleton';

import { appChevronRight20Icon } from '@icons/chevron-right-20';

import { BreadcrumbsViewModel } from './breadcrumbs.view-model';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [SvgIconComponent, RouterLink, NgClass, AsyncPipe, LoadingSkeletonComponent],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  public vm = input.required<BreadcrumbsViewModel>();

  constructor() {
    injectRegisterIcons([appChevronRight20Icon]);
  }
}
