import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Provider } from '@angular/core';

import { LOCALE_GB } from '@shared/utils/locales';

const localeDataFactory = () => {
  return (): void => {
    registerLocaleData(LOCALE_GB);
  };
};

export const localeDataInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: localeDataFactory,
  deps: [],
  multi: true,
};
