import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { localeDataInitializer } from '@core/initializers/locale-data';
import { themeInitializer } from '@core/initializers/theme';
import { translationsPreloadInitializer } from '@core/initializers/translations-preload';
import { websocketInitializer } from '@core/initializers/websocket';
import { luxonAdapterProviders } from '@core/luxon-adapter';
import { WebsocketEffects } from '@core/stores/websocket/websocket.effects';
import { websocketFeatureKey, websocketReducer } from '@core/stores/websocket/websocket.reducer';

import { routes } from './app.routes';
import { iconProvider, provideTranslate } from './core/providers';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideStore({
      [websocketFeatureKey]: websocketReducer,
      router: routerReducer,
    }),
    provideEffects(WebsocketEffects),
    provideRouterStore(),
    provideHttpClient(withFetch()),
    provideTranslate(),
    iconProvider,
    translationsPreloadInitializer,
    localeDataInitializer,
    websocketInitializer,
    themeInitializer,
    ...luxonAdapterProviders,
    provideAnimations(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  ],
};
