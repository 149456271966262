/* eslint-disable @typescript-eslint/no-magic-numbers */
import Color from 'color';

import { ThemeColors } from './theme-colors';
import { CustomThemeDropShadows, GeneratedThemeDropShadows } from './theme-drop-shadows';
import { ThemeRadiusSizes } from './theme-radius-sizes';

const foregroundPrimary100 = '#000000';
const foregroundSecondary100 = '#FFFFFF';
const actionPrimary100 = '#009B77';
const actionSecondary100 = '#006FB5';

const chargingstatusReady100 = '#009B77';
const chargingstatusCharging100 = '#0277C8';
const chargingstatusQueued100 = '#FFA400';
const chargingstatusFaulted100 = '#FF0000';
const chargingstatusInDepot100 = '#687985';
const chargingstatusOnRoad100 = '#B0BDC6';

const statusSuccess100 = '#009B77';
const statusError100 = '#FF0000';
const statusWarning100 = '#FF7A00';
const statusInfo100 = '#239FD0';

const stroke100 = '#000000';

const headerBreadcrumbCurrent = '#FFFFFF';
const headerTabText = '#FFFFFF';

export const cssThemeColorVariables: Record<(typeof ThemeColors)[keyof typeof ThemeColors], string> = {
  '--color-background-main': '#E2EAEF',
  '--color-background-fixed': '#FFFFFF',
  '--color-background-moveable': '#E8EEF2',
  '--color-background-menu': '#FFFFFF',
  '--color-background-panel': '#F1F5F8',
  '--color-background-container-primary': '#FFFFFF',
  '--color-background-container-secondary': '#E2EAEF',
  '--color-background-container-inverse': '#000000',
  '--color-background-border': '#FFFFFF',

  '--color-foreground-primary-100': foregroundPrimary100,
  '--color-foreground-primary-80': Color(foregroundPrimary100).alpha(0.8).string(),
  '--color-foreground-primary-60': Color(foregroundPrimary100).alpha(0.6).string(),
  '--color-foreground-primary-40': Color(foregroundPrimary100).alpha(0.4).string(),
  '--color-foreground-primary-20': Color(foregroundPrimary100).alpha(0.2).string(),
  '--color-foreground-primary-12': Color(foregroundPrimary100).alpha(0.12).string(),
  '--color-foreground-primary-8': Color(foregroundPrimary100).alpha(0.08).string(),

  '--color-foreground-secondary-100': foregroundSecondary100,
  '--color-foreground-secondary-80': Color(foregroundSecondary100).alpha(0.8).string(),
  '--color-foreground-secondary-60': Color(foregroundSecondary100).alpha(0.6).string(),
  '--color-foreground-secondary-40': Color(foregroundSecondary100).alpha(0.4).string(),
  '--color-foreground-secondary-20': Color(foregroundSecondary100).alpha(0.2).string(),
  '--color-foreground-secondary-12': Color(foregroundSecondary100).alpha(0.12).string(),
  '--color-foreground-secondary-8': Color(foregroundSecondary100).alpha(0.08).string(),

  '--color-action-primary': actionPrimary100,
  '--color-action-primary-30': Color(actionPrimary100).alpha(0.3).string(),
  '--color-action-primary-text': '#0A734F',
  '--color-action-secondary': actionSecondary100,
  '--color-action-secondary-30': Color(actionSecondary100).alpha(0.3).string(),
  '--color-action-secondary-text': '#005197',

  '--color-chargingstatus-ready-100': chargingstatusReady100,
  '--color-chargingstatus-ready-30': Color(chargingstatusReady100).alpha(0.3).string(),
  '--color-chargingstatus-charging-100': chargingstatusCharging100,
  '--color-chargingstatus-charging-30': Color(chargingstatusCharging100).alpha(0.3).string(),
  '--color-chargingstatus-queued-100': chargingstatusQueued100,
  '--color-chargingstatus-queued-30': Color(chargingstatusQueued100).alpha(0.3).string(),
  '--color-chargingstatus-faulted-100': chargingstatusFaulted100,
  '--color-chargingstatus-faulted-30': Color(chargingstatusFaulted100).alpha(0.3).string(),
  '--color-chargingstatus-indepot-100': chargingstatusInDepot100,
  '--color-chargingstatus-indepot-30': Color(chargingstatusInDepot100).alpha(0.3).string(),
  '--color-chargingstatus-onroad-100': chargingstatusOnRoad100,
  '--color-chargingstatus-onroad-30': Color(chargingstatusOnRoad100).alpha(0.3).string(),
  '--color-chargingstatus-fg': '#FFFFFF',

  '--color-chart-ev': '#0277C8',
  '--color-chart-baseload': '#A768B2',
  '--color-chart-pv-solar': '#FFD600',
  '--color-chart-grid': '#000000',
  '--color-chart-battery': '#82E9A2',

  '--color-header-title': '#FFFFFF',
  '--color-header-breadcrumb-current': headerBreadcrumbCurrent,
  '--color-header-breadcrumb-past': Color(headerBreadcrumbCurrent).alpha(0.6).string(),
  '--color-header-tab-text': headerTabText,
  '--color-header-tab-text-80': Color(headerTabText).alpha(0.8).string(),
  '--color-header-tab-line': '#FFFFFF',
  '--color-header-actionitem-bg': '#FFFFFF',
  '--color-header-actionitem-fg': '#000000',

  /**
   *  'header-title': '--color-header-title',
  'header-breadcrumb-current': '--color-header-breadcrumb-current',
  'header-breadcrumb-past': '--color-header-breadcrumb-past',
  'header-tab-text': '--color-header-tab-text',
  'header-tab-line': '--color-header-tab-line',
  'header-text': '--color-header-text',
  'header-actionitem-bg': '--color-header-actionitem-bg',
  'header-actionitem-fg': '--color-header-actionitem-fg',
   */

  '--color-status-success': statusSuccess100,
  '--color-status-success-30': Color(statusSuccess100).alpha(0.3).string(),
  '--color-status-success-text': '#006945',
  '--color-status-error': statusError100,
  '--color-status-error-30': Color(statusError100).alpha(0.3).string(),
  '--color-status-error-20': Color(statusError100).alpha(0.2).string(),
  '--color-status-error-text': '#CD0000',
  '--color-status-warning': statusWarning100,
  '--color-status-warning-30': Color(statusWarning100).alpha(0.3).string(),
  '--color-status-warning-text': '#D56B0A',
  '--color-status-info': statusInfo100,
  '--color-status-info-30': Color(statusInfo100).alpha(0.3).string(),
  '--color-status-info-text': '#0076B3',

  '--color-stroke-100': stroke100,
  '--color-stroke-8': Color(stroke100).alpha(0.08).string(),
};

export type ColorCssVariables = keyof typeof cssThemeColorVariables;

export const cssThemeRadiusSizesVariables: Record<(typeof ThemeRadiusSizes)[keyof typeof ThemeRadiusSizes], string> = {
  '--size-radius-primary': '16px',
  '--size-radius-secondary': '8px',
  '--size-radius-tertiary': '4px',
  '--size-radius-24': '24px',
  '--size-radius-circle': '9999px',
};

export const cssThemeCustomDropShadowsVariables: Record<(typeof CustomThemeDropShadows)[keyof typeof CustomThemeDropShadows], string> = {
  '--drop-shadow-chargingstatus-charging': `0 0 10px ${Color(chargingstatusCharging100).alpha(0.6).string()}`,
  '--drop-shadow-chargingstatus-queued': `0 0 10px ${Color(chargingstatusQueued100).alpha(0.6).string()}`,
  '--drop-shadow-foreground-primary-100': `0 0 10px ${Color(chargingstatusOnRoad100).alpha(0.6).string()}`,
  '--drop-shadow-chargingstatus-ready': `0 0 10px ${Color(chargingstatusReady100).alpha(0.6).string()}`,
};

export const cssThemeGeneratedDropShadowsVariables: Record<string, string> = Object.values(GeneratedThemeDropShadows).reduce(
  (acc, value) => {
    return {
      ...acc,
      [value.cssVariable]: `0 7px 20px ${Color(cssThemeColorVariables[ThemeColors[value.themeColorKey]]).alpha(0.3).string()}`,
    };
  },
  {} as Record<string, string>
);
