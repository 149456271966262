import { ThemeColors } from './theme-colors';

export const CustomThemeDropShadows = {
  'chargingstatus-charging': '--drop-shadow-chargingstatus-charging',
  'chargingstatus-queued': '--drop-shadow-chargingstatus-queued',
  'foreground-primary-100': '--drop-shadow-foreground-primary-100',
  'chargingstatus-ready': '--drop-shadow-chargingstatus-ready',
} as const;

type GeneratedThemeDropShadows = {
  [K in keyof typeof ThemeColors as `${K}-shadow-30`]: {
    themeColorKey: K;
    cssVariable: string;
  };
};
export const GeneratedThemeDropShadows: GeneratedThemeDropShadows = Object.keys(ThemeColors).reduce((acc, key) => {
  const typedKey = key as keyof typeof ThemeColors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (acc as any)[`${typedKey}-shadow-30`] = {
    themeColorKey: typedKey,
    cssVariable: `${ThemeColors[typedKey]}-shadow-30`,
  };
  return acc;
}, {} as GeneratedThemeDropShadows);
