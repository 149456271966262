export const ThemeColors = {
  'background-main': '--color-background-main',
  'background-fixed': '--color-background-fixed',
  'background-moveable': '--color-background-moveable',
  'background-menu': '--color-background-menu',
  'background-panel': '--color-background-panel',
  'background-container-primary': '--color-background-container-primary',
  'background-container-secondary': '--color-background-container-secondary',
  'background-container-inverse': '--color-background-container-inverse',
  'background-border': '--color-background-border',

  'foreground-primary-100': '--color-foreground-primary-100',
  'foreground-primary-80': '--color-foreground-primary-80',
  'foreground-primary-60': '--color-foreground-primary-60',
  'foreground-primary-40': '--color-foreground-primary-40',
  'foreground-primary-20': '--color-foreground-primary-20',
  'foreground-primary-12': '--color-foreground-primary-12',
  'foreground-primary-8': '--color-foreground-primary-8',
  'foreground-secondary-100': '--color-foreground-secondary-100',
  'foreground-secondary-80': '--color-foreground-secondary-80',
  'foreground-secondary-60': '--color-foreground-secondary-60',
  'foreground-secondary-40': '--color-foreground-secondary-40',
  'foreground-secondary-20': '--color-foreground-secondary-20',
  'foreground-secondary-12': '--color-foreground-secondary-12',
  'foreground-secondary-8': '--color-foreground-secondary-8',

  'action-primary': '--color-action-primary',
  'action-primary-30': '--color-action-primary-30',
  'action-primary-text': '--color-action-primary-text',
  'action-secondary': '--color-action-secondary',
  'action-secondary-30': '--color-action-secondary-30',
  'action-secondary-text': '--color-action-secondary-text',

  'chargingstatus-ready-100': '--color-chargingstatus-ready-100',
  'chargingstatus-ready-30': '--color-chargingstatus-ready-30',
  'chargingstatus-charging-100': '--color-chargingstatus-charging-100',
  'chargingstatus-charging-30': '--color-chargingstatus-charging-30',
  'chargingstatus-queued-100': '--color-chargingstatus-queued-100',
  'chargingstatus-queued-30': '--color-chargingstatus-queued-30',
  'chargingstatus-faulted-100': '--color-chargingstatus-faulted-100',
  'chargingstatus-faulted-30': '--color-chargingstatus-faulted-30',
  'chargingstatus-indepot-100': '--color-chargingstatus-indepot-100',
  'chargingstatus-indepot-30': '--color-chargingstatus-indepot-30',
  'chargingstatus-onroad-100': '--color-chargingstatus-onroad-100',
  'chargingstatus-onroad-30': '--color-chargingstatus-onroad-30',
  'chargingstatus-fg': '--color-chargingstatus-fg',

  'chart-ev': '--color-chart-ev',
  'chart-baseload': '--color-chart-baseload',
  'chart-pv-solar': '--color-chart-pv-solar',
  'chart-grid': '--color-chart-grid',
  'chart-battery': '--color-chart-battery',

  'header-title': '--color-header-title',
  'header-breadcrumb-current': '--color-header-breadcrumb-current',
  'header-breadcrumb-past': '--color-header-breadcrumb-past',
  'header-tab-text': '--color-header-tab-text',
  'header-tab-text-80': '--color-header-tab-text-80',
  'header-tab-line': '--color-header-tab-line',
  'header-actionitem-bg': '--color-header-actionitem-bg',
  'header-actionitem-fg': '--color-header-actionitem-fg',

  'status-success': '--color-status-success',
  'status-success-30': '--color-status-success-30',
  'status-success-text': '--color-status-success-text',
  'status-error': '--color-status-error',
  'status-error-30': '--color-status-error-30',
  'status-error-20': '--color-status-error-20',
  'status-error-text': '--color-status-error-text',
  'status-warning': '--color-status-warning',
  'status-warning-30': '--color-status-warning-30',
  'status-warning-text': '--color-status-warning-text',
  'status-info': '--color-status-info',
  'status-info-30': '--color-status-info-30',
  'status-info-text': '--color-status-info-text',

  'stroke-8': '--color-stroke-8',
  'stroke-100': '--color-stroke-100',
} as const;
