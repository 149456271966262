import { createSelector } from '@ngrx/store';

import {
  selectCollapsedLogoHeight,
  selectCollapsedLogoSrc,
  selectCollapsedLogoWidth,
  selectExpandedLogoHeight,
  selectExpandedLogoSrc,
  selectExpandedLogoWidth,
} from '@core/stores/config/config.selectors';

import { SideMenuViewModel } from './components/side-menu';
import { selectSideMenuExpanded } from './store/layout.selectors';

export const selectSideMenuViewModel = createSelector(
  selectSideMenuExpanded,
  selectExpandedLogoSrc,
  selectExpandedLogoWidth,
  selectExpandedLogoHeight,
  selectCollapsedLogoSrc,
  selectCollapsedLogoWidth,
  selectCollapsedLogoHeight,
  (
    sideMenuExpanded,
    expandedLogoSrc,
    expandedLogoWidth,
    expandedLogoHeight,
    collapsedLogoSrc,
    collapsedLogoWidth,
    collapsedLogoHeight
  ): SideMenuViewModel => ({
    expanded: sideMenuExpanded,
    expandedLogoSrc,
    expandedLogoWidth,
    expandedLogoHeight,
    collapsedLogoSrc,
    collapsedLogoWidth,
    collapsedLogoHeight,
  })
);
