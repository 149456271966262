import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConfigState, configFeatureKey } from './config.reducer';

export const selectConfigFeature = createFeatureSelector<ConfigState>(configFeatureKey);

export const selectExpandedLogoSrc = createSelector(selectConfigFeature, (state) => state.expandedLogoSrc);
export const selectExpandedLogoWidth = createSelector(selectConfigFeature, (state) => state.expandedLogoWidth);
export const selectExpandedLogoHeight = createSelector(selectConfigFeature, (state) => state.expandedLogoHeight);
export const selectCollapsedLogoSrc = createSelector(selectConfigFeature, (state) => state.collapsedLogoSrc);
export const selectCollapsedLogoWidth = createSelector(selectConfigFeature, (state) => state.collapsedLogoWidth);
export const selectCollapsedLogoHeight = createSelector(selectConfigFeature, (state) => state.collapsedLogoHeight);
export const selectDotHeaderMode = createSelector(selectConfigFeature, (state) => state.dotHeaderMode);
