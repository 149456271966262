import { Routes } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { RouteSegment } from '@core/enums/route-segment.enum';
import { configFeatureKey, configReducer } from '@core/stores/config/config.reducer';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardEffects } from './pages/dashboard/store/dashboard.effects';
import { dashboardFeatureKey, dashboardReducer } from './pages/dashboard/store/dashboard.reducer';
import { LayoutComponent } from './pages/layout';
import { layoutFeatureKey, layoutReducer } from './pages/layout/store/layout.reducer';

export const routes: Routes = [
  {
    path: RouteSegment.Root,
    component: LayoutComponent,
    providers: [
      provideState({
        name: configFeatureKey,
        reducer: configReducer,
      }),
      provideState({
        name: layoutFeatureKey,
        reducer: layoutReducer,
      }),
    ],
    children: [
      {
        path: RouteSegment.Root,
        pathMatch: 'full',
        component: DashboardComponent,
        providers: [
          provideState({
            name: dashboardFeatureKey,
            reducer: dashboardReducer,
          }),
          provideEffects(DashboardEffects),
        ],
      },
      {
        path: RouteSegment.Sites,
        children: [
          {
            path: ':id',
            loadChildren: () => import('./pages/single-site/single-site.routes').then((m) => m.SingleSiteRoutes),
          },
        ],
      },
    ],
  },
];
