import { provideSvgIconsConfig } from '@ngneat/svg-icon';

export const iconProvider = provideSvgIconsConfig({
  sizes: {
    xs: '10px',
    sm: '12px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '32px',
    xxxl: '40px',
    '32': '32px',
    '40': '40px', // TODO change existing sizes to pixel names
  },
  defaultSize: 'lg',
});
