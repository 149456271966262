import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  cssThemeColorVariables,
  cssThemeCustomDropShadowsVariables,
  cssThemeGeneratedDropShadowsVariables,
  cssThemeRadiusSizesVariables,
} from '@core/theme/css-variables';

const themeFactory = () => {
  return (): Observable<void> => {
    const root = document.querySelector<HTMLElement>(':root')!;

    Object.keys(cssThemeColorVariables).forEach((key) => {
      root.style.setProperty(key, cssThemeColorVariables[key as keyof typeof cssThemeColorVariables]);
    });

    Object.keys(cssThemeRadiusSizesVariables).forEach((key) => {
      root.style.setProperty(key, cssThemeRadiusSizesVariables[key as keyof typeof cssThemeRadiusSizesVariables]);
    });

    Object.keys(cssThemeCustomDropShadowsVariables).forEach((key) => {
      root.style.setProperty(key, cssThemeCustomDropShadowsVariables[key as keyof typeof cssThemeCustomDropShadowsVariables]);
    });

    Object.keys(cssThemeGeneratedDropShadowsVariables).forEach((key) => {
      root.style.setProperty(key, cssThemeGeneratedDropShadowsVariables[key]);
    });

    return of(void 0);
  };
};

export const themeInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: themeFactory,
  deps: [],
  multi: true,
};
