import { createReducer, on } from '@ngrx/store';

import { connectToWebsocket, connectToWebsocketSuccess, websocketClosed, websocketFailure } from './websocket.actions';

export interface WebsocketState {
  online: boolean | null;
  connectionInProgress: boolean;
  websocketError?: string;
}

export const initialState: WebsocketState = {
  online: null,
  connectionInProgress: false,
};

export const websocketFeatureKey = 'websocket';

export const websocketReducer = createReducer(
  initialState,

  on(connectToWebsocket, (state) => ({ ...state, websocketError: undefined, connectionInProgress: true })),
  on(connectToWebsocketSuccess, (state) => ({ ...state, online: true, connectionInProgress: false })),
  on(websocketFailure, (state, { error }) => ({ ...state, connectionInProgress: false, websocketError: error })),
  on(websocketClosed, (state) => ({ ...state, online: false, websocketError: undefined }))
);
