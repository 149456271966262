import { Injectable, OnDestroy, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectAssetGraphHistoryGraphViewModel,
  selectDashboardAssetDetailsVm,
  selectDashboardAssetSelectorVm,
  selectDashboardSiteDetailsVm,
  selectDashboardSiteSelectorVm,
  selectSiteGraphHistoryGraphViewModel,
  selectSiteOverviewTableViewModel,
} from './dashboard.selectors';
import { clearDashboardData, loadSiteList, selectAsset, selectSite } from './store/dashboard.actions';

@Injectable()
export class DashboardService implements OnDestroy {
  readonly #store = inject(Store);

  public readonly siteSelectorVm = this.#store.selectSignal(selectDashboardSiteSelectorVm);
  public readonly siteDetailsVm = this.#store.selectSignal(selectDashboardSiteDetailsVm);
  public readonly siteOverviewTableVm = this.#store.selectSignal(selectSiteOverviewTableViewModel);
  public readonly siteGraphHistoryGraphVm = this.#store.selectSignal(selectSiteGraphHistoryGraphViewModel);
  public readonly assetSelectorVm = this.#store.selectSignal(selectDashboardAssetSelectorVm);
  public readonly assetDetailsVm = this.#store.selectSignal(selectDashboardAssetDetailsVm);
  public readonly assetGraphHistoryGraphVm = this.#store.selectSignal(selectAssetGraphHistoryGraphViewModel);

  constructor() {
    this.#store.dispatch(loadSiteList());
  }

  public ngOnDestroy(): void {
    this.#store.dispatch(clearDashboardData());
  }

  public selectSite(siteId: string): void {
    this.#store.dispatch(selectSite({ siteId }));
  }

  public selectAsset(assetId: string): void {
    this.#store.dispatch(selectAsset({ assetId }));
  }
}
