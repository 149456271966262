/* eslint-disable unicorn/consistent-function-scoping */
import { Injectable, OnDestroy, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { injectRegisterIcons } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, isObservable, of, switchMap } from 'rxjs';

import { RouteSegment } from '@core/enums/route-segment.enum';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { selectDotHeaderMode } from '@core/stores/config/config.selectors';

import { appDashboard16Icon } from '@icons/dashboard-16';

import { BreadcrumbItemViewModel, BreadcrumbsViewModel } from './components/breadcrumbs';
import { HeaderProfileSectionViewModel } from './components/header-profile-section';
import { selectSideMenuViewModel } from './layout.selectors';
import { setSideMenuExpanded } from './store/layout.actions';

@Injectable()
export class LayoutService implements OnDestroy {
  readonly #store = inject(Store);
  readonly #breadcrumbsService = inject(BreadcrumbsService);
  readonly #translateService = inject(TranslateService);

  private readonly breadcrumbItem: BreadcrumbItemViewModel = {
    route: [RouteSegment.Root],
    name$: this.#translateService.stream('side-menu.dashboard'),
    icon: 'dashboard-16',
  };

  public readonly sideMenuVm = this.#store.selectSignal(selectSideMenuViewModel);
  public readonly headerProfileVm = signal<HeaderProfileSectionViewModel>({
    name: 'Jonathan Smith',
    hasNotifications: true,
  });
  private readonly dotHeaderMode = this.#store.selectSignal(selectDotHeaderMode);
  private readonly breadcrumbs = toSignal(
    this.#breadcrumbsService.items$.pipe(
      switchMap((items) => {
        const itemObservables = items.map((item$): Observable<BreadcrumbItemViewModel> => {
          return isObservable(item$) ? item$ : of(item$);
        });

        if (itemObservables.length === 0) {
          return of([]);
        }

        return combineLatest(itemObservables);
      })
    ),
    {
      requireSync: true,
    }
  );
  public readonly breadcrumbsVm = computed((): BreadcrumbsViewModel => {
    const dotHeaderModeValue = this.dotHeaderMode();
    const breadcrumbsValue = this.breadcrumbs();

    return {
      darkMode: dotHeaderModeValue,
      items: breadcrumbsValue,
    };
  });
  public readonly title$Signal = computed((): Observable<string> => {
    const breadcrumbsVm = this.breadcrumbsVm();

    return breadcrumbsVm.items.at(-1)!.name$;
  });

  constructor() {
    injectRegisterIcons(appDashboard16Icon);
    this.#breadcrumbsService.addItem(this.breadcrumbItem);
  }

  public ngOnDestroy(): void {
    this.#breadcrumbsService.removeItem(this.breadcrumbItem);
  }

  public onSideMenuExpandChange(expanded: boolean): void {
    this.#store.dispatch(setSideMenuExpanded({ expanded }));
  }
}
