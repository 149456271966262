@for (item of vm().items; track item.route; let first = $first, last = $last) {
  <a
    [routerLink]="item.route"
    class="flex items-center gap-2 text-header-breadcrumb-past hover:text-header-breadcrumb-current transition-colors"
    [ngClass]="{
      'text-header-breadcrumb-past hover:text-header-breadcrumb-current': !vm().darkMode && !last,
      'text-header-breadcrumb-current': !vm().darkMode && last,
      'text-foreground-primary-80 hover:text-foreground-primary-100': vm().darkMode && !last,
      'text-foreground-primary-100': vm().darkMode && last,
    }"
  >
    @if (item.icon) {
      <svg-icon [key]="item.icon" size="md"></svg-icon>
    }
    @if (vm().items.length === 1 || !first) {
      @if (item.name$ | async; as name) {
        <span>{{ name }}</span>
      } @else {
        <app-loading-skeleton class="w-[50px] h-[20px]" viewBox="0 0 50 20">
          <svg:rect x="0" y="0" rx="4" ry="4" width="50" height="20" />
        </app-loading-skeleton>
      }
    }
  </a>
  @if (!last) {
    <svg-icon
      key="chevron-right-20"
      size="lg"
      [ngClass]="{
        'text-header-breadcrumb-past ': !vm().darkMode,
        'text-foreground-primary-80': vm().darkMode,
      }"
    ></svg-icon>
  }
}
