import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SvgIconComponent, injectRegisterIcons } from '@ngneat/svg-icon';

import { appNotification40Icon } from '@icons/notification-40';
import { appProfile40Icon } from '@icons/profile-40';

import { HeaderProfileSectionViewModel } from './header-profile-section.view-model';

@Component({
  selector: 'app-header-profile-section',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './header-profile-section.component.html',
  styleUrl: './header-profile-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderProfileSectionComponent {
  public vm = input.required<HeaderProfileSectionViewModel>();

  constructor() {
    injectRegisterIcons([appNotification40Icon, appProfile40Icon]);
  }
}
